<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <form @submit.prevent="login">
              <v-card class="elevation-12">
                <v-toolbar color="primary" dark flat>
                  <v-toolbar-title>Connexion</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
                <v-card-text>
                  <v-alert type="error" text prominent v-if="message != ''">
                    {{ message }}
                  </v-alert>

                  <v-text-field
                    label="E-mail"
                    v-model="email"
                    prepend-icon="mdi-at"
                    type="email"
                  />

                  <v-text-field
                    label="Mot de passe"
                    v-model="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    required
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn :loading="loading" color="primary" type="submit">
                    Se connecter
                  </v-btn>
                </v-card-actions>
              </v-card>
            </form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { auth } from "../../../main";
export default {
  data() {
    return {
      loading: false,
      message: "",
      email: "",
      password: "",
      alert: false
    };
  },
  methods: {
    stopLogin() {
      auth.signOut();
      this.loading = false;
      this.message = "Non autorisé.";
    },
    async login() {
      this.loading = true;
      this.message = "";

      await auth.signInWithEmailAndPassword(this.email, this.password);
      this.$router.push("planning");
    }
  }
};
</script>
